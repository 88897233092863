<template>
  <div id="app">
    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="(unit, i) in cronUnits" :key="i">
        <a :class="[navClass, activeTab === i ? activeClass : '']" aria-current="page" href="#" @click="switchTab(i)">{{ unit.name }}</a>
      </li>
    </ul>
    <CronInput :class="hiddenInput" :style="{ display: activeTab === i ? 'block' : ''}" @time-unit-changed="updateCronPart" v-for="(unit, i) in cronUnits" :input-title="unit.name" :units="unit.value" :key="i" :checkbox-columns="unit.checkboxColumns" :checkbox-width="unit.checkboxWidth" :time-options="unit.timeOptions" />
    <div class="result">
      <div v-for="(part, i) in this.cronParts" :key="i" class="cron-element">
        {{part.value}}
      </div>
      <button @click="copyCronExpression">Copy</button>
    </div>
  </div>
</template>

<script>
import CronInput from './components/CronInput.vue'

export default {
  name: 'App',
  components: {
    CronInput,
  },
  data() {
    return {
      timePeriods: ['Second', 'Minute', 'Hour'],
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      cronParts: ['Second', 'Minute', 'Hour', 'Day of the month', 'Month', 'Day of the week'].map(
        part => {
          return {
            unit: part,
            value: '*'
          }
        }
      ),
      activeTab: 0,
      navClass: 'nav-link',
      activeClass: 'active',
      hiddenInput: 'hidden-input',
      tabs: [0, 1]
    }
  },
  computed: {
    finalCronExpression() {
      return this.cronParts.map(part => part.value).join(' ')
    },
    cronUnits() {
      return [
        {name: 'Second', value: this.getNumbers(60), timeOptions: true,},
        {name: 'Minute', value: this.getNumbers(60), timeOptions: true,},
        {name: 'Hour', value: this.getNumbers(24), timeOptions: true, checkboxColumns: 8, checkboxWidth: '100px'},
        {name: 'Day of the month', value: this.getNumbers(31)},
        {name: 'Month', value: this.months, checkboxColumns: 6, checkboxWidth: '120px'},
        {name: 'Day of the week', value: this.weekDays, checkboxColumns: 4, checkboxWidth: '160px'},
      ]
    },
  },
  methods: {
    updateCronPart(part) {
      this.cronParts = this.cronParts.map(cronPart => {
        if (cronPart.unit !== part.name) return cronPart
        return {
          ...cronPart,
          value: part.value
        }
      })
    },
    copyCronExpression() {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = this.finalCronExpression;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
    getNumbers(max) {
      const units = []
      let index = 1
      while (index <= max) {
        units.push(index)
        index++
      }
      return units
    },
    switchTab(tab) {
      this.activeTab = tab
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 20px;
}

.result {
  padding-top: 20px;
  font-size: 34px;
  font-weight: 700;
  display: flex
}

.hidden-input {
  display: none;
}

.cron-element {
  margin: 0 10px;
  padding: 4px;
  background-color: lightgrey;
}

a {
  font-size: 20px;
}
</style>
