<template>
  <div>
   <h2>
    {{ inputTitle }}
   </h2>
    <div class="input-wrapper wrapper" ref="option-0" @click="setMethod(0)">
      <input type="radio" class="form-check-input" :checked="selectedOption === 0">
      <div class="select-text">
        Every {{ unit }}
      </div>
    </div>
    <div v-if="timeOptions" class="input-wrapper wrapper" ref="option-1" @click="setMethod(1)">
      <input type="radio" class="form-check-input" :checked="selectedOption === 1">
      <div class="select-text">
        Every
      </div>
      <div>
        <input :min="0" :max="units.length" type="number" v-model="everyUnit" name="every-unit" @change="(e) => validateMaxInput(e, everyUnit)">
      </div>
      <div class="error-message" ref="every-unit">
        max. value: {{units.length}}
      </div>
      <div class="select-text">
        {{ unit }}(s)
      </div>
    </div>
    <div ref="option-2" class="wrapper" @click="setMethod(2)">
      <div class="flex">
        <input type="radio" class="form-check-input" :checked="selectedOption === 2">
        <div class="select-text">
          Select {{ unit }}(s)
        </div>
      </div>
      <div class="checkbox-wrapper" :style="{gridTemplateColumns: gridProperties}">
        <div v-for="(unit, i) in unitsMapped" :key="i">
          <input type="checkbox" :id="unit.name" v-model="unit.checked" @change="unitsEdited">
          <label>{{unit.name}}</label>
        </div>
      </div>
    </div>
    <div v-if="timeOptions" class="input-wrapper wrapper" ref="option-3" @click="setMethod(3)">
      <input type="radio" class="form-check-input" :checked="selectedOption === 3">
      <div class="select-text">
        Every {{ unit }} between
      </div>
      <div>
        <input :min="0" :max="units.length" type="number" name="between-unit-one" v-model="betweenUnitOne" @change="(e) => validateBetweenInput(e, betweenUnitOne)">
      </div>
      <div class="error-message" ref="between-unit-one">
        max. value: {{units.length}}
      </div>
      <div class="select-text">
        and
      </div>
      <div>
        <input :min="0" :max="units.length" type="number" name="between-unit-two" v-model="betweenUnitTwo" @change="(e) => validateBetweenInput(e, betweenUnitTwo)">
      </div>
      <div class="error-message" ref="between-unit-two">
        max. value: {{units.length}}
      </div>
      <div class="error-message" ref="between-units">
        first value larger than second value
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormatInput",
  props: {
    inputTitle: {
      required: true,
      type: String,
    },
    units: {
      type: Array,
      required: true,
    },
    timeOptions: {
      type: Boolean,
      default: false,
    },
    checkboxColumns: {
      type: Number,
      default: 10,
    },
    checkboxWidth: {
      type: String,
      default: '80px',
    },
  },
  data() {
    return {
      everyUnit: 0,
      specificUnit: '*',
      betweenUnitOne: 0,
      betweenUnitTwo: 0,
      selectedOption: null
    }
  },
  computed:{
    unit() {
      return this.inputTitle.toLowerCase()
    },
    unitsMapped() {
      return this.units.map((unit, i) => {
        return {
          value: i + 1,
          name: unit,
          checked: false,
        }
      })
    },
    gridProperties() {
      return `repeat(${this.checkboxColumns}, ${this.checkboxWidth})`
    }
  },
  methods: {
    setMethod(param) {
      this.setSelectedMethod(param)
      switch(param) {
        case 0: this.setResult('*')
          break
        case 1: this.setResult(`*/${this.getDisplayUnitValue(this.everyUnit)}`)
          break
        case 2: this.setResult(`${this.specificUnit}`, true)
          break
        case 3: this.setResult(`${this.getDisplayUnitValue(this.betweenUnitOne)}-${this.getDisplayUnitValue(this.betweenUnitTwo)}`)
          break
      }
    },
    getDisplayUnitValue(val) {
      return val > this.units.length ? this.units.length : !val ? 0 : val
    },
    setResult(val, isSpecificValue = false) {
      if (isSpecificValue) this.specificUnit = val
      this.$emit('time-unit-changed', {
        name: this.inputTitle,
        value: val ? val : '*',
      })
    },
    unitsEdited() {
      this.unitValue = this.unitsMapped.filter(unit => unit.checked).map(unit => unit.value).join(',')
      this.specificUnit = this.unitValue
      this.setResult(`${this.unitValue}`, true)
    },
    setSelectedMethod(option) {
      this.selectedOption = option
      const optionElements = this.timeOptions ? [0,1,2,3] : [0,2]
      optionElements.forEach(num => this.$refs[`option-${num}`].classList.remove('selected-option'))
      this.$refs[`option-${option}`].classList.add('selected-option')
    },
    validateMaxInput(e, value) {
      const invalidValue = parseInt(value) > parseInt(e.target.max)
      this.toggleErrorMessage(e.target.name, invalidValue)
    },
    validateBetweenInput(e, value) {
      const invalidValue = parseInt(this.betweenUnitOne) >= parseInt(this.betweenUnitTwo)
      this.validateMaxInput(e, value)
      this.toggleErrorMessage('between-units', invalidValue)
    },
    toggleErrorMessage(fieldName, isError) {
      const errorBox = this.$refs[fieldName]
      const errorClasses = errorBox.classList
      isError ? errorClasses.add('visible') : errorClasses.remove('visible')
    }
  }
}
</script>

<style scoped>
  .input-wrapper {
    display: flex;
    column-gap: 6px;
    align-items: center;
  }

  .wrapper:hover {
    cursor: pointer;
  }

  .checkbox-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 120px);
    padding-left: 24px;
  }

  .select-text {
    font-size: 24px;
  }

  .selected-option {
    background-color: darkgray;
    color: white;
  }

  .error-message {
    padding: 4px;
    color: red;
    display: none;
  }

  .visible {
    display: block;
  }

  .flex {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  input[type=text],
  input[type=number] {
    width: 70px;
  }

  input[type=checkbox] {
    height: 18px;
    width: 18px;
  }

  h2 {
    padding-top: 10px;
    font-weight: 800;
  }

  label {
    font-size: 20px;
    padding-left: 6px;
  }
</style>
